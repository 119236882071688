@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Color variables */
:root {
  --jet: #28282A;
  --flame: #CF5C36;
  --cambrigde-blue: #00C2D1;
  --glaucous: #717EC3;
  --pale-dogwood: #E8DDB5;
  --off-white: #ddd;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

button {
  font-family: 'Montserrat', sans-serif;
  background-color: transparent;
  cursor: pointer;
  border: none;
}

html {
  transition: all 0.2s ease;
}

.terms-container {
  padding: 0 10%;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  background-color: var(--glaucous);
  text-align: left;
  font-size: 10pt;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.login-page footer {
  background-color: var(--jet);
  color: var(--off-white);
}

.login-page footer a {
  color: var(--off-white);
}

.login-page button[type="submit"] {
  background-color: var(--jet);
  color: var(--off-white);
  border-radius: 10px;
  padding: 5px 20px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.2s ease;
}

footer p {
  margin: 0;
  padding: 10px;
}

footer a {
  text-decoration: none;
  color: black;
}

.login-page {
  background-color: var(--cambrigde-blue);
}

.login-page input[type="text"], .login-page input[type="password"], .login-page input[type="email"] {
  border-radius: 5px;
  border: 1px solid var(--off-white);
  padding: 5px;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.signup-form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.login-page .form-group {
  display: inline;
}

.login-page .form-group label {
  margin-right: 10px;
}

#googleSignInButton {
  background-color: white;
  color: #3c4043;
  border: none;
  padding: 10px 20px;
  border-radius: 2px;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
}

#googleSignInButton img {
  margin-right: 0px;
  width: 20px;
  height: 20px;
}

#googleSignInButton span {
  margin-left: 10px;
}

#googleSignInButton:hover {
  background-color: #F7FAFC;
}

#googleSignInButton:active {
  background-color: #F7FAFC;
}

.projects-container {
  height: 100%;
  min-height: 100vh;
  background-color: var(--jet);
}

.notice-section {
  background-color: var(--glaucous);
  padding: 2px 10px;
  margin-bottom: 20px;
}

.notice-section a:link, .notice-section a:visited {
  color: var(--jet)
}

.main-projects-area {
  display: block;
  width: 60vw;
  margin: auto;
  flex-grow: 1;
  overflow: hidden;
}

.main-projects-area h1 {
  color: var(--off-white);
  font-size: 30pt;
  text-align: center;
  width: fit-content;
}

.label-new-project {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.projects-list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  margin-bottom: 60px;
}

.project-item {
  width: 100%;
  background-color: var(--off-white);
  border-radius: 10px;
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--jet);
  transition: all 0.2s ease;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,1);
}

.project-item:hover {
  background-color: var(--flame);
  color: var(--off-white);
  cursor: pointer;
}

.project-item p {
  font-size: 10pt;
}

.project-item h3 {
  font-size: 14pt;
  margin: auto 10px;
  width: 60%;
  word-wrap: break-word;
}

.delete-project-button {
  margin-right: 10px;
}

.project-item-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.new-project-button {
  margin-left: 25px;
  padding: 10px 20px;
  background-color: var(--cambrigde-blue);
  border-radius: 10px;
  color: var(--jet);
  font-size: 24px;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,1);
}

.new-project-button:hover {
  opacity: 0.8;
}

.modal-overlay {
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */
}

.modal-content {
  background-color: var(--cambrigde-blue);
  border-radius: 20px;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  width: 50%; /* Could be more or less, depending on screen size */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  animation-name: animatetop;
  animation-duration: 0.4s;
  display: flex;
  flex-direction: column;
}

.modal-content input {
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  border: none;
}

.modal-content textarea {
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  resize: none;
  font-family: "Montserrat", sans-serif;
  border-radius: 5px;
  border: none;
}

/* Add Animation */
@keyframes animatetop {
  from {top: -300px; opacity: 0}
  to {top: 0; opacity: 1}
}

/* Container for the whole app */
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: --var(--glaucous);
}

/* NavBar styles */
.navbar {
  display: flex;
  align-items: center; /* This vertically centers the content */
  justify-content: space-between; /* This horizontally centers the content, if needed */
  flex: 0 0 36px; /* Adjust height as needed */
  background-color: var(--cambrigde-blue); /* Example background color */
  border-bottom: 1px solid var(--glaucous);
  font-size: 10pt;
}

.navbar * {
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.navbar .run-flow-button:hover {
  cursor: pointer;
  opacity: 0.8;
}

.run-flow-button {
  position: relative;
  overflow: hidden;
  background-color: #CF5C36;
  color: #ddd;
  padding: 5px 10px;
  border: none;
  outline: none;
  cursor: pointer;
  /* animation: pulse 2s infinite; */
}

.run-flow-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0) 50%);
  animation: shimmer 8s infinite;
  background-size: 200% 100%;
}

@keyframes shimmer {
  0% {
      background-position: -150%;
  }
  50% {
      background-position: 150%;
  }
  100% {
      background-position: 150%;
  }
}

@keyframes pulse {
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.05);
  }
  100% {
      transform: scale(1);
  }
}

.navbar .home-button {
  color: var(--jet);
  font-size: 12pt;
  text-decoration: none;
}

.flow-status-bar {
  display: flex;
  flex-direction: row;
  margin: auto 0;
  margin-right: 20px;
  padding: 0;
  justify-content: flex-end;
}

.flow-status-bar * {
  margin: auto 0;
}

.flow-status-bar p {
  margin-top: auto;
  margin-bottom: auto;
  color: black;
  padding: 0;
  width: fit-content;
  margin-right: 15px;
}

.flow-status-bar .fa {
  margin-right: 0;
}

/* Main area below the NavBar */
.main-area {
  display: flex;
  flex: 1; /* Take remaining space */
  overflow: hidden; /* In case of overflow */
  background-color: var(--glaucous);
}

/* File Explorer styles */
.file-explorer {
  flex: 0 0 20%; /* Adjust width as needed */
  background-color: var(--jet); /* Example background color */
  overflow-y: auto; /* For scrolling */
  color: var(--off-white);
  font-size: 10pt;
  padding-top: 10px;
  padding-bottom: 10px;
  border-right: 1px solid var(--glaucous);
}

.file-explorer .folder-name {
  padding: 2px 5px;
  margin: 0;
  cursor: pointer;
}

.file-explorer .folder-icon {
  color: var(--cambrigde-blue);
}

.file-explorer .file-name{
  padding: 2px 5px;
  cursor: pointer;
  width: fit-content;
  margin: 0;
}

.file-explorer .file-name:hover {
  color: var(--flame);
  border-radius: 3px;
}

.file-explorer .file-name.active-file {
  color: var(--flame);
  border-radius: 3px;
}

/* Container for Code Editor and Terminal */
.editor-terminal-container {
  overflow: scroll;
  display: flex;
  flex-direction: column;
  flex: 1; /* Take remaining space */
}

/* Code Editor styles */
.code-editor {
  flex: 2; /* Adjust proportion as needed */
  overflow: auto; /* For scrolling */
  /* border-bottom: 0.5px solid var(--glaucous); */
}

/* Overlay Backdrop */
.terminal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

/* New Scrollable Content Wrapper */
.terminal-scrollable-content {
  /* height: 100%; */
  /* overflow-y: auto; */
  /* position: relative; */
  margin-top: 10px;
}

/* Terminal Popup */
.terminal {
  /* overflow: hidden; */
  position: relative;
  background-color: black;
  color: limegreen;
  font-family: 'Courier New', Courier, monospace;
  padding: 20px;
  width: 60vw; /* Adjust as needed */
  height: 60vh; /* Adjust as needed */
  /* overflow-y: auto; */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  z-index: 1001; /* Above the backdrop */
}

.close-button {
  margin-left: 15px;
  /* position: absolute; */
  /* top: 10px;
  right: 10px; */
  color: white;
  border: none;
  /* border-radius: 5px; */
  /* padding: 5px 10px; */
  cursor: pointer;
  /* z-index: 1002; */
}

.terminal-cancel-button {
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  background-color: limegreen;
  color: black;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  margin-right: 15px;
}

/* Ensure the content does not overlap with the button */
.terminal-content {
  /* padding-top: 10px; */
  background-color: black;
}

.terminal-line {
  margin: 0;
}

.tabs-container {
  display: inline;
  background-color: var(--jet);
  font-size: 10pt;
  overflow: scroll;
  width: 100%;
  white-space: nowrap;
}

.tab {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-right: 1px solid var(--glaucous);
  border-bottom: none;
  cursor: pointer;
  color: var(--off-white);
}

.tab button {
  color: var(--off-white);
}

.tab.active {
  border-bottom: 3px solid var(--glaucous);
  color: var(--glaucous);
}

.tab.active button {
  color: var(--glaucous);
}

.tab button {
  margin-left: 10px;
  cursor: pointer;
}

.file-upload-popup, .name-input-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
}

.file-upload-content, .name-input-content {
  background-color: var(--cambrigde-blue);
  border-radius: 20px;
  margin: 15% auto;
  padding: 20px 100px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  animation-name: animatetop;
  animation-duration: 0.4s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.name-input-content input {
  border-radius: 5px;
  border: 1px solid var(--off-white);
  padding: 5px;
}

.name-input-content button {
  margin: 0px 5px;
  cursor: pointer;
}

.context-menu-item {
  cursor: pointer;
  padding: 3px 5px;
}

.context-menu-item:hover {
  background-color: var(--glaucous);
}

.account-container {
  width: 100%;
  height: 100vh;
  background-color: var(--jet);
  color: var(--off-white);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.account-container * {
  margin-top: 10px;
}

.account-container h1 {
  font-size: 28pt;
  font-weight: normal;
}

.popup-message-outer {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
}

.popup-message-inner {
  background-color: var(--cambrigde-blue);
  border-radius: 20px;
  margin: 15% auto;
  padding: 20px 50px;
  width: fit-content;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  animation-name: animatetop;
  animation-duration: 0.4s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.popup-message-inner h1 {
  font-size: 18pt;
  font-weight: normal;
}