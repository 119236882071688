:root {
  --jet: #28282A;
  --flame: #CF5C36;
  --cambrigde-blue: #00C2D1;
  --glaucous: #717EC3;
  --pale-dogwood: #E8DDB5;
  --off-white: #ddd;
}

.rel-loading {
  position: absolute; /* or relative */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1010;
  background-color: var(--jet);
}

.rel-loading-spinner {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 80px;
  height: 80px;
  margin: -40px 0 0 -40px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: var(--flame);
  z-index: 1500;

  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.rel-loading-spinner:before {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: var(--cambrigde-blue);

  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

.rel-loading-spinner:after {
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: var(--glaucous);

  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1010;
  background-color: var(--jet);
}

.loading-spinner {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--flame);
  z-index: 1500;

  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loading-spinner:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--cambrigde-blue);

  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

.loading-spinner:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--glaucous);

  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  0%   {
      -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(0deg);  /* IE 9 */
      transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
  }
  100% {
      -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(360deg);  /* IE 9 */
      transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
  }
}

@keyframes spin {
  0%   {
      -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(0deg);  /* IE 9 */
      transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
  }
  100% {
      -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(360deg);  /* IE 9 */
      transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
  }
}